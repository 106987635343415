<template>
	<div v-if="from == 'tiers' || from == 'gyneco'" id="content">
		<div v-if="booted" class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="box">
						<div class="alert alert-info" v-if="info_nb_horse">{{ info_nb_horse_msg }}</div>
						<!-- Si la limite a été atteinte -->
						<template v-if="!has_right">
							<template v-if="(isAndroidRendering || isRegularRendering)">
								<RegularBuyPlan @purchased="hideRegularPurchase" />
							</template>
						</template>

						<!-- En online, on authorise l'ajout auto et manuel -->
						<template v-else-if="isOnline">
							<!-- Input recherche -->
							<div class="form-group search mb-0 row">
								<div class="col-12 col-md">
									<input ref="search_input_tiers" type="search" name="search" class="form-control " id="search" autofocus="true" v-model="global_input_search" :placeholder="$t('global.input_recherche_cheval')">
								</div>
								<div class="col-12 col-md-auto mt-3 mt-md-0">
									<button type="submit" class="w-100 btn btn-primary">{{ $t('global.rechercher') }} <font-awesome-icon :icon="['fal', 'search']" class="ml-2" /></button>
								</div>
							</div>

							<!-- Spinner -->
							<template  v-if="isWorking || input_type_working == 1" >
								<LoadingSpinner />
							</template>
							<template v-else-if="booted">
								<!-- Résultats -->
								<template v-if="result_horses.length">
									<CustomTable
										v-show="!isWorking"
										id_table="horse_ajout"
										:items="formatted_horses"
										:busy.sync="table_busy"
										primaryKey="horse_sire"
										@row-select="addHorseMethod"
										:checkboxes="false"
										:filtre_general="false"
										selectMode="single"
										:rowSelectable="!isWorking"
										:externSlotColumns="extern_slot_columns"
									>
										<template v-slot:custom-slot-cell(button_add)="{ data }">
											<button class="btn btn-primary" type="button" @click.prevent="addHorseMethod(data)">{{ $t('global.add_this_horse') }}</button>
										</template>
										<template v-slot:custom-slot-cell(horse_race)="{ data }">
										{{ $t('race.' + data.horse_race) }}
										</template>
									</CustomTable>
								</template>
								<!-- Code erreur courant sur les autres plateformes -->
								<div v-else-if="global_input_search != '' && global_input_search.length < input_search_requirements" class="mt-2">
									{{ $t('horse.add_search_requirement') }}
								</div>
								<template v-else-if="code_erreur != '' || (formatted_horses.length == 0 && global_input_search != '')">
									<div class="mt-5">
										<b-alert variant="danger" show>{{ error_message_trad }}</b-alert>
									</div>
								</template>
							</template>

						</template>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<LoadingSpinner />
		</div>
	</div>
	<main v-else>
		<HeaderTab :title="$t('horse.ajout_cheval')"/>
		<div id="content">
	        <div v-if="booted" class="container-fluid">
	            <div class="row">
	                <div class="col-12">
	                	<div class="box">
							<div class="alert alert-info" v-if="info_nb_horse">{{ info_nb_horse_msg }}</div>
							<!-- Si la limite a été atteinte -->
							<template v-if="!has_right">
								<template v-if="(isAndroidRendering || isRegularRendering)">
									<RegularBuyPlan @purchased="hideRegularPurchase" />
								</template>
							</template>

							<!-- En offline, on authorise l'ajout manuel -->
							<template v-else-if="!isOnline">
								<div class="text-center">
									<h5>{{ $t('global.actually_offline') }} <font-awesome-icon :icon="['fas', 'wifi-slash']" /></h5>
									<router-link  :to="{ name: 'horseCreation' }" class="add_horse btn btn-primary rounded-pill">{{ $t('horse.ajouter_manuellement') }} <font-awesome-icon :icon="['fal', 'angle-right']" /></router-link>
								</div>
							</template>

							<!-- En online, on authorise l'ajout auto et manuel -->
							<template v-else>
								<!-- Input recherche -->
								<div class="form-group search mb-0 row">
									<div class="col-12 col-md">
										<input ref="search_input" type="search" name="search" class="form-control " id="search" autofocus="true" v-model="global_input_search" :placeholder="$t('global.input_recherche_cheval')">
									</div>
									<div class="col-12 col-md-auto mt-3 mt-md-0">
										<button type="submit" class="w-100 btn btn-primary">{{ $t('global.rechercher') }} <font-awesome-icon :icon="['fal', 'search']" class="ml-2" /></button>
									</div>
									<div class="col-12 col-md-auto mt-3 mt-md-0">
										<router-link  :to="{ name: 'horseCreation' }" class="w-100 btn btn-primary">{{ $t('horse.manual_add') }} <font-awesome-icon :icon="['fal', 'angle-right']" /></router-link>
									</div>
								</div>

								<!-- Spinner -->
								<template  v-if="isWorking || input_type_working == 1" >
									<LoadingSpinner />
								</template>
								<template v-else-if="booted">
									<!-- Résultats -->
									<template v-if="result_horses.length">
										<CustomTable
											v-show="!isWorking"
											id_table="horse_ajout"
											:items="formatted_horses"
											:busy.sync="table_busy"
											primaryKey="horse_sire"
											@row-select="addHorseMethod"
											:checkboxes="false"
											:filtre_general="false"
											selectMode="single"
											:rowSelectable="!isWorking"
											:externSlotColumns="extern_slot_columns"
										>
											<template v-slot:custom-slot-cell(button_add)="{ data }">
												<button class="btn btn-primary" type="button" @click.prevent="addHorseMethod(data)">{{ $t('global.add_this_horse') }}</button>
											</template>
										</CustomTable>
									</template>
									<!-- Code erreur courant sur les autres plateformes -->
									<div v-else-if="global_input_search != '' && global_input_search.length < input_search_requirements" class="mt-2">
										{{ $t('horse.add_search_requirement') }}
									</div>
									<template v-else-if="code_erreur != '' || (formatted_horses.length == 0 && global_input_search != '')">
										<div class="mt-5">
											<b-alert variant="danger" show>{{ error_message_trad }}</b-alert>
										</div>
									</template>
								</template>

							</template>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<LoadingSpinner />
			</div>
		</div>
	</main>
</template>
<script type="text/javascript">
	import Constants from '../../config.local.js'

	import Navigation from '@/mixins/Navigation.js'
	import Horse from "@/mixins/Horse.js";
	import Request from '@/assets/js/requests.js'
	import Common from '@/assets/js/common.js'

	import _debounce from 'lodash/debounce';

	export default {
		name: "horseAjout",
		mixins: [Navigation, Horse],
		props: ['from', 'horseToAddName',"horseSexeMandatory"],
		data() {
			return {
				request: new Request(this.$db),
				connectivity: this.$connectivity,
				first_login: false,
				has_right: false,
				global_input_search: "",
				result_horses: [],
				selected_horse: null,
				code_erreur: "",
				search_type: "", // SIRE / UELN / NAME
				search_done: false, // passe à true dès qu'on a un résultat sur la recherche, et repasse à false dès qu'on passe en dessous des 5 cara, ou qu'il n'y a pas de résultat sur le filtre local
				search_loading: false, // passe à true juste avant la recherche, et repasse à false sur le résultat
				search_origin_name: "", // nom du cheval retenu, lors d'un résultat de la recherche
				add_progress: 0, // passe à 1 au clic sur un cheval
				bool_adding_horse: false, // permet de mettre un verrou, pour éviter les doubles requetes

				search_horse: 0,		// booléen pour afficher ou non le spinner sur bouton recherche
				input_type_detected: 0,	// Booléen permettant de bloquer la recherche tant que le type de recherche n'a pas été identifié
				input_type_working: 0,	// Booléen passant à 1 lorsque la recherche de type se fait, repasse à 0 après, même si la recherche n'a pas abouti
				tab_trad_manual_add: [
					'IK',
					'IF',
					'NOR',
				], // Nom, père mère, race, robe sexe, date de naissance
				tab_data_correspondance: {
					ajout: {
						horse_nom:  'birthName',
						horse_pere: 'sire.birthName',
						horse_mere: 'dam.birthName',
						horse_race: 'breedCode',
						horse_robe: 'colourLabel',
						horse_sexe: 'sex',
						horse_date: 'birthDate',
					},
					ajout_nom: {
						horse_nom:  'nom',
						horse_pere: 'genealogy.sireName',
						horse_mere: 'genealogy.damName',
						horse_race: 'codeRace',
						horse_robe: 'codeRobe',
						horse_sexe: 'codeSexe',
						horse_date: 'dateNaissance',
						horse_sire: 'numeroSireEncode'
					}
				},
				input_search_requirements: 5, // Nombre de caractères requis pour que la recherche ne se déclanche
				input_search_recommanded: 8, // Nombre de caractères requis pour que la recherche ne se déclanche

                /* Configuration du tableau : */
				table_busy: true,/* Définit si la table n'est pas encore chargée */
				booted: false,
				limit_horse: 0, // Nombre de chevaux déjà ajoutés,

				formatted_horses: [],
				pwa_render_platform: 'regular', // regular (web) / ios:safari / ios:wkwebview
				extern_slot_columns: ['button_add', "horse_race"],
				mask_ifce: false,
				nb_horse: 0,
				info_nb_horse: false,
				info_nb_horse_msg: ''
			}
		},
		created: function() {
			this.debouncedInputTypeRecognition = _debounce(this.inputTypeRecognitionV2, 1000)
			this.check_nb_horses()
		},

		mounted() {
			this.init_component()
		},

		methods: {
			init_component() {
				this.first_login = this.$route.params.first_login
				if(this.horseToAddName != undefined) {
					this.global_input_search = this.horseToAddName
				}

				this.mask_ifce = this.getConfigIfce()
				this.mask_weatherbys = this.getConfigWeatherbys()
				if(this.mask_ifce) {
					if(this.mask_weatherbys) {
						this.$router.push({ name: 'horseCreation' })
					}
					this.$router.push({ name: 'SearchWeatherbys' })
				}
			},
			check_nb_horses: async function() {
				// On compte le nombre de chevaux déjà ajoutés, via la taille de la whitelist du localstorage
				this.limit_horse = this.getUserHorseLimit()

				this.nb_horse = await this.getNbHorse()
				if(this.limit_horse - this.nb_horse < 10 && this.limit_horse - this.nb_horse > 0)
				{
					if(this.limit_horse - this.nb_horse == 1) {
						this.info_nb_horse_msg = this.getTrad('global.info_limit_horse_unique', [this.limit_horse - this.nb_horse])
					}
					else {
						this.info_nb_horse_msg = this.getTrad('global.info_limit_horse_multiple', [this.limit_horse - this.nb_horse])
					}
					
					this.info_nb_horse = true
				}

				// On regarde sur l'api si on a toujours le droit d'ajouter des nouveaux chevaux 
				this.has_right = await this.checkCanAdd()

				if(!this.has_right) {
					this.code_erreur = "NR"
				}
				this.booted = true
			},

			inputTypeRecognitionV2: function(input) {
				// On force le spinner à se masquer
				this.input_type_working = 0
				let result_to_clean = false

				// regex caractères apple
				input = this.clean_input(input)

				// On regarde d'abord si la regex de base match
				if(this.regex_alpha_fr_nc.test(input)) {
					this.input_type_detected = 0
					this.code_erreur = "LICD" // Local - Illegal caracter detected
				}
				else {
					// On démarre l'analyse à patir de 5 caractères, et on lance nos tests de regex pour définir quel type de recherche est fait
					if(input.length >= this.input_search_requirements) {
						this.code_erreur = ""

						// On commence par tester les reges d'erreurs possibles
						if(!this.checkPotentialError(input)) {

							// On cherche une preuve de SIRE - on cherche par ordre de précision
							if(this.regex_sire.test(input)) { // sire ok
								this.input_type_working = 1
								this.search_type = "SIRE"
								this.fetchHorse(input)
							}

							// On cherche une preuve d'UELN - on cherche par ordre de précision
							else if(this.regex_ueln.test(input)) { // sire ok
								this.input_type_working = 1
								this.search_type = "UELN"
								this.fetchHorse(input)
							}

							// Si aucune preuve de pattern n'abouti, on fait une recherche par nom
							else if(this.regex_name_like.test(input)) {
								this.input_type_working = 1
								this.search_type = "NAME"
								this.fetchHorseByName(input)
							}
						}
						else {
							result_to_clean = true
						}
					}
					else {
						result_to_clean = true
					}
				}

				if(result_to_clean) {
					this.result_horses = []
				}
			},

			checkPotentialError: function(input) {
				// On va tester les différentes regex d'erreurs - on test de la plus simple à la plus restrictive
				let error = false

				/* UELN */
				if(this.regex_ueln_like.test(input)) {

					if(this.regex_incomplete_ueln_car.test(input)) {
						error = true
						this.code_erreur = "LTTL"
					}
					else if(this.regex_incomplete_ueln_cle.test(input)) {
						error = true
						this.code_erreur = "LTKM"
					}
					else if(this.regex_incomplete_ueln_num.test(input)) {
						error = true
						this.code_erreur = "LTTN"
					}
					// else if(this.regex_ueln_error.test(input)) {
					// 	error = true
					// 	this.code_erreur = "LTMF"
					// }
					else if(!this.regex_ueln.test(input)) {
						error = true
						this.code_erreur = "LTII"
					}
				}
				/* SIRE */
				else if(this.regex_sire_like.test(input)) {

					if(this.regex_incomplete_sire_cle.test(input)) {
						error = true
						this.code_erreur = "LKIM"
					}
					else if(this.regex_incomplete_sire_num.test(input)) {
						error = true
						this.code_erreur = "LNIM"
					}
					else if(this.regex_incomplete_sire_car.test(input)) {
						error = true
						this.code_erreur = "LTML"
					}
					else if(!this.regex_sire.test(input)) {
						error = true
						this.code_erreur = "LSII"
					}
				}

				return error
			},

			fetchHorse: async function(input) {
				this.result_horses = []
				let url = ""

				input = this.accentsTidy(input)
				input = input.toUpperCase()
				if(this.search_type == "SIRE") {
					url = Constants.SEARCH_SIRE_URL + '?sire='+encodeURI(input)+'&licence_key='+Constants.USER_LICENCE_KEY
				}
				else if (this.search_type == "UELN") {
					url = Constants.SEARCH_UELN_URL + '?ueln='+encodeURI(input)+'&licence_key='+Constants.USER_LICENCE_KEY
				}

				const response = await this.request.request_get_api("HorseAjout::fetchHorse", url)

				if(response.code_retour === 0) {
					if(response.retour) {
						this.result_horses.push(response.retour)
					}
				}
				else {
					this.code_erreur = response.code_retour
				}

				// On cache le spinner, et on indique que la recherche du cheval est terminée
				this.input_type_working = 0
				this.search_horse = 0
			},

			fetchHorseByName: async function(name) {
				// On vérifie les verrou
				if(!this.search_loading && !this.search_done) {
					this.result_horses = []
					this.search_loading = true

					const url = Constants.SEARCH_NAME_URL+this.accentsTidy(name.toUpperCase())+"?licence_key="+Constants.USER_LICENCE_KEY
					await this.request.request_get_api("HorseAjout::fetchHorseByName", url)
					.then(response => {
						if(response.code_retour === 0) {
							if(response.retour) {
								if(this.horseSexeMandatory){
									const array = []
									for(const horse of response.retour){
										if(this.horseSexeMandatory.includes(horse.codeSexe)){
											array.push(horse)
										}
									}
									if(array.length > 0){
										this.result_horses = this.format_horse_result(array) // le retour est un tableau, pas besoin de push
									} else {
										this.noHorseForSexe = true;
									}
								} else {
									this.result_horses = this.format_horse_result(response.retour) 
								}
								this.search_done = true // si on a un résultat, on active le verrou pour ne pas relancer la recherche
								this.search_origin_name = name
							}
						}
					})
					.catch(e => {
						const code_erreur = e.response.data.code_retour ? e.response.data.code_retour : "UKE"
						if(code_erreur == "UKE" && name.length < this.input_search_recommanded) {
							this.code_erreur = "TMR"
						}
						else {
							this.code_erreur = code_erreur
						}
					})

					// On cache le spinner, et on indique que la recherche du cheval est terminée
					this.input_type_working = 0
					this.search_horse = 0
					this.search_loading = false

					// On regarde si entre temps, l'input a changé, et qu'on cherche toujours un nom. Si on a pas eu de résultats du webservice, on relance la recherche
					let input = this.clean_input(this.global_input_search)
					if(input != name && this.search_type == "NAME" && this.result_horses.length == 0 && input.length >= 5) {
						this.fetchHorseByName(input)
					}
				}
				else {
					this.input_type_working = 0
				}
			},

			addHorseMethod: function(item) {
				if(!item) return
				// Pour empêcher le double clic par ex
				if(this.add_progress !== 0) return

				this.add_progress = 1

				if(this.search_type == "SIRE" || this.search_type == "UELN") {
					const selected_horse = this.filtred_horses[0]
					this.addHorseBySire(selected_horse)
				}
				else if(this.search_type == "NAME") {
					// Pour retrouver le cheval selectionné, on se base sur le sireEncode, unique pour chaque cheval
					const selected_horse = this.filtred_horses.filter(horse => horse.numeroSireEncode == item.horse_sire)
					if(selected_horse.length > 0)
						this.addHorseByName(selected_horse)
				}
			},

			addHorseBySire: async function(horse) {
				try {
					let horse_exists = await this.sireAlreadyExists(horse.sireNumber, horse.sireKey)

					if(!horse_exists) {
						if(horse != null && horse != 0) {
							this.bool_adding_horse = true

							// On valide le cheval auprès du webservice
							const horse_id = horse.id
							const url = Constants.VALIDE_HORSE_URL+horse_id+'/?licence_key='+Constants.USER_LICENCE_KEY
							const response = await this.request.request_get_api("horseAjout::addHorse", url, false, { sync: true })

							if(response.code_retour === 0 && !this.from) {
								this.addHorseResidence(horse_id)
							}
							else if(response.code_retour === 0 && (this.from == 'tiers' || this.from == 'gyneco')) {
								this.$emit('update:horse_id', horse_id)
							}

							this.bool_adding_horse = false
						}
					}
					else {
						this.failureToast(this.getTrad("horse.already_exists"), true)
						this.add_progress = 0
					}
				}
				catch(e) {
					this.add_progress = 0
				}
			},

			addHorseByName: async function(horse) {
				try {
					if(horse.length > 0)
						horse = horse[0]

					// On effectue une requete pour récupérer le sire décodé du cheval
					const encoded_sire = horse.numeroSireEncode
					const url = Constants.SIRE_DECODE_URL+"?encode_sire="+encoded_sire+"&licence_key="+Constants.USER_LICENCE_KEY

					if(encoded_sire.length > 0 && this.bool_adding_horse == false) {
						this.bool_adding_horse = true
						const response = await this.request.request_get_api("HorseAjout::addHorseByName", url)

						// Si on a un retour viable
						if(response.code_retour === 0) {
							if(response.retour) {
								const horse = response.retour
								// On a le sire décodé, on peut effectuer l'ajout du cheval par le processus normal (par sire)
								await this.addHorseBySire(horse)
								this.bool_adding_horse = false
							}
						}
						else {
							this.code_erreur = response.code_retour;
						}
					}

					// On cache le spinner, et on indique que la recherche du cheval est terminée
					this.input_type_working = 0
					this.search_horse = 0
				}
				catch(e) {
					this.add_progress = 0
				}
			},

			// On redirige vers l'ajout de mouvement, pour renseigner la résidence du cheval
			addHorseResidence: async function(horse_id) {
				// On envoie le horse_id pour l'ajout du mouvement, et le nom de cette vue pour le from
				if(this.getConfig('skip_residence_onboarding')) {
					this.$router.push({ name: 'horseListe' })
				}
				else {
					this.$router.push({ name: 'mouvementAjout', params: { horse_id: horse_id, from: this.$options.name, type: 'entree' }})
				}
			},

			get_result_horse_nom: function(horse) {
				if(this.search_type == "NAME") {
					return horse.nom
				}
				return horse.birthName
			},
			get_resut_horse_pere: function(horse) {
				if(this.search_type == "NAME") {
					return horse.genealogy.sireName
				}
				return horse.sire.birthName
			},
			get_resut_horse_mere: function(horse) {
				if(this.search_type == "NAME") {
					return horse.genealogy.damName
				}
				return horse.dam.birthName
			},
			// Fonction permettant de rajouter des attributs aux objets horse
			format_horse_result: function(horses) {
				horses.forEach(horse => {
					horse.clicked = false
				})

				return horses
			},
			clean_input: function(input) {
				if(!input) return input
				input = input.toUpperCase()
				input = input.replace(/[\u2018\u2019]/g, "'")
				input = input.replace(new RegExp(/[èéêë]/g),"e")
				return input
			},
			async format_horses() {
				this.table_busy = true
				this.formatted_horses = []
				if(!this.filtred_horses || this.filtred_horses.length === 0) {
					return
				}
				if(this.search_type != "NAME") {
					this.formatted_horses.push(
						await this.formatDataHorseAjout(this.filtred_horses[0], this.tab_data_correspondance.ajout)
					)
				}
				else {
					// On parcourt chaque résultat, pour le formatter
					for (let index in this.filtred_horses) {
						const obj_api = this.filtred_horses[index]
						this.formatted_horses.push(
							await this.formatDataHorseAjout(obj_api, this.tab_data_correspondance.ajout_nom)
						)
					}
				}

				this.table_busy = false
			},
			hideRegularPurchase() {
				this.code_erreur = ''
				this.pwa_render_platform = 'regular'
				this.has_right = true
			}
		},

		computed: {
			error_message_trad: function() {
				if(this.tab_trad_manual_add.indexOf(this.code_erreur) > -1) {
					return this.getTrad("horse.introuvable")
				}
				else if(this.code_erreur == 'NR') {
					return this.getTrad("error.NR", [this.limit_horse])
				}
				return this.getTrad("error."+(this.code_erreur == "" ? "UKE" : this.code_erreur))
			},
			filtred_horses: function() {
				// Si la recherche n'est pas par nom, pas de filter à appliquer
				if(this.search_type != "NAME") {
					return this.result_horses
				}
				const input = this.clean_input(this.global_input_search)
				return this.result_horses.filter(horse => this.accentsTidy(horse.nom.toLowerCase()).includes(this.accentsTidy(input.toLowerCase())))
			},
			isWorking() {
				return this.add_progress == 1
			},
			isAndroidRendering() {
				return this.pwa_render_platform == 'android'
			},
			isRegularRendering() {
				return this.pwa_render_platform == 'regular'
			},
			isOnline() {
				return window.navigator.onLine
			}
		},

		watch: {
			global_input_search(val) {
				if(val != "") {
					this.input_type_working = 1
					// On surveille le changement de l'input nécessitant un realod de la recherche par nom
					const val_clean = this.clean_input(val)
					if(!val_clean) return null
					if(val_clean.length < this.search_origin_name.length) {
						this.search_origin_name = ""
						this.search_done = false
					}
					else if(val_clean.length == this.search_origin_name.length && val_clean != this.search_origin_name) {
						this.search_origin_name = ""
						this.search_done = false
					}

					this.debouncedInputTypeRecognition(val)
				}
			},
			filtred_horses() {
				this.format_horses()
			},
			booted(v) {
				if(v && (this.from == 'tiers' || this.from == 'gyneco')) {
					this.$nextTick(function () {
						this.$refs.search_input_tiers.focus()
					})
				}
				else if(v) {
					this.$nextTick(function () {
						this.$refs.search_input.focus()
					})
				}
			}
		},

		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			RegularBuyPlan: () => import('@/components/Plans/BuyPlan')
		}
	};
</script>
